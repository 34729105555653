import { useContext, useEffect, useMemo, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import { navigate } from "gatsby";

import {
  AMOUNT_KEY_FOR_URL_PARAMS_FIX_PAYMENT,
  PRODUCT_CODE_KEY_FOR_URL_PARAMS_FIX_PAYMENT,
} from "@/constants";
import UserContext from "@/contexts/User/UserContext";
import { getUrlParameterByKey } from "@/utils/urlUtils";

import { getProduct, getProductVariables } from "./graphql/__generated__/getProduct";
import { GET_PRODUCT } from "./graphql/GET_PRODUCT";
import { UseFixPriceForServiceProductsResultType } from "./types";

export const useFixPriceForServiceProducts = (): UseFixPriceForServiceProductsResultType => {
  const [amountForProduct, setAmountForProduct] = useState<number | null>(null);
  const [productID, setProductId] = useState<number | null>(null);
  const { isUserLoggedIn } = useContext(UserContext);

  const productCode = useMemo(
    () =>
      getUrlParameterByKey(PRODUCT_CODE_KEY_FOR_URL_PARAMS_FIX_PAYMENT) || null,
    [],
  );

  const amountFromUrl = useMemo(() => {
    if (getUrlParameterByKey(AMOUNT_KEY_FOR_URL_PARAMS_FIX_PAYMENT)) {
      return Number(getUrlParameterByKey(AMOUNT_KEY_FOR_URL_PARAMS_FIX_PAYMENT));
    }
    return null;
  }, []);

  const isFixPaymentByProductIdFromUrl = useMemo(() =>
    !!productCode, [productCode]);

  const isFixPaymentByAmountFromUrl = useMemo(() =>
    !!amountFromUrl, [amountFromUrl]);

  const [
    getProductQuery,
    {
      data: productsData,
      error: productsError,
    },
  ] = useLazyQuery<getProduct, getProductVariables>(GET_PRODUCT);

  useEffect(() => {
    if (isFixPaymentByAmountFromUrl) {
      setAmountForProduct(amountFromUrl);
    }

    if (isUserLoggedIn && isFixPaymentByProductIdFromUrl && productCode) {
      getProductQuery({ variables: { filter: { code: productCode } } });
    }
  }, [amountFromUrl,
    getProductQuery,
    isFixPaymentByAmountFromUrl,
    isFixPaymentByProductIdFromUrl,
    isUserLoggedIn,
    productCode]);

  useEffect(() => {
    if (productsError) {
      navigate("/404");
    } else if (productsData?.getProduct) {
      if (!productsData.getProduct.active) navigate("/404");
      setProductId(productsData.getProduct.id);
      setAmountForProduct(productsData.getProduct.amount || 0);
    }
  }, [productsData, productsError]);

  return {
    isFixPaymentForService: isFixPaymentByProductIdFromUrl || isFixPaymentByAmountFromUrl,
    amountForProduct,
    productID,
  };
};
