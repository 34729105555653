import React, { useContext, useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import {
  GET_MY_PROFILE_FOR_HEADER,
} from "@layout/header/graphql/GET_MY_PROFILE_FOR_HEADER";
import UserMenu from "@layout/header/User/UserMenu";
import { FormTypeEnum } from "@layout/modals/types";
import { setAuthParamToURL } from "@layout/modals/utils";
import { useLocation } from "@reach/router";
import * as Sentry from "@sentry/gatsby";
import { navigate } from "gatsby";

import { Currency } from "@/autoGeneratedGlobalTypes";
import FreeMinutesDisplay from "@/components/common/freeMinutesDisplay";
import { IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { UserAvatar } from "@/components/common/userAvatar";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";
import { DeviceTypeEnum } from "@/globalTypes";
import UseOutsideClick from "@/hooks/useOutsideClick";
import { detectDeviceType, isBrowser } from "@/utils/env";
import { currencyToString } from "@/utils/globalTypesUtils";
import { priceToString } from "@/utils/numberUtils";
import {
  Button,
  ButtonColorEnum,
  ButtonIconPositionEnum,
  ButtonSizeEnum,
} from "@components/common/button";
import Loader from "@components/common/loader";
import { UserAvatarTypeEnum } from "@components/common/userAvatar/types";
import UserInfo from "@components/common/userInfo";

import { getMyProfileForHeader } from "../graphql/__generated__/getMyProfileForHeader";

import { userMenuProps } from "./types";

import "./styles.scss";

const User = ({ setRedirectMobileProfile }: userMenuProps) => {
  const {
    setUUID,
    setEmail,
    balance,
    setBalance,
    unfinishedPaymentId,
    isUserLoggedIn,
    setFreeMinutesCount,
    freeMinutesCount,
  } = useContext(UserContext);
  const { setIsExpert, isExpert } = useContext(GlobalContext);

  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const [
    getMyProfile,
    {
      loading: getMyProfileLoading,
      error: getMyProfileError,
      data: getMyProfileData,
    },
  ] = useLazyQuery<getMyProfileForHeader>(GET_MY_PROFILE_FOR_HEADER, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const onAuthorizationLinkClick = () => {
    setAuthParamToURL(location, FormTypeEnum.Login);
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      getMyProfile();
    }
  }, [getMyProfile, isUserLoggedIn]);

  useEffect(() => {
    if (getMyProfileData && !getMyProfileLoading && !getMyProfileError) {
      const {
        balance: balanceByData,
        email,
        user: {
          id,
          uuid,
          freeMinutesLeft,
        },
        isExpert: isExpertProfileData,
      } = getMyProfileData.getMyProfile;

      setUUID(uuid);
      setIsExpert(isExpertProfileData);
      setFreeMinutesCount(freeMinutesLeft);
      setBalance(balanceByData);
      setEmail(email);

      Sentry.setUser({ id: id.toString() });
    }
  }, [getMyProfileData,
    getMyProfileLoading,
    getMyProfileError,
    setUUID,
    setIsExpert,
    setFreeMinutesCount,
    setBalance,
    setEmail,
  ]);

  const submenu = UseOutsideClick(() => {
    setIsOpen((prev) =>
      // State фризится на инициализированном значении
      // поэтому решаем, показывать или нет в событии его переназначения
      (prev ? !prev : prev));
  }, "mousedown");

  function clickOnMobile(e: React.MouseEvent) {
    if (isBrowser()) {
      e.preventDefault();

      if (detectDeviceType() !== DeviceTypeEnum.Desktop) {
        setIsOpen((prev) =>
          !prev);
      }
    }
  }

  return (
    <div className="header__user-container">
      {getMyProfileData && isUserLoggedIn && !isExpert && (
        <>
          {freeMinutesCount !== 0
            && (
              <div
                onClick={() =>
                  (!isUserLoggedIn ? onAuthorizationLinkClick() : navigate("/profile/balance"))}
              >
                <FreeMinutesDisplay iconSize={IconSizeEnum.Size24} withMinutes />
              </div>
            )}
          <div
            className="header-balance"
            onClick={() =>
              navigate("/profile/payment/")}
          >
            <div className="header-balance__info">
              <div className="header-balance__title">На балансе</div>
              <div className="header-balance__value">
                {unfinishedPaymentId ? (
                  <div className="header__balance-loader">
                    <Loader />
                  </div>
                ) : (
                  <div className="header__balance-amount">
                    {balance ? priceToString(balance.amount) : 0}
                    &nbsp;
                    {balance ? currencyToString(balance.currency) : Currency.RUB}
                  </div>
                )}
              </div>
            </div>
            <Button
              className="header-balance__button"
              aria-label="Пополнить баланс"
              size={ButtonSizeEnum.XSmall}
              color={ButtonColorEnum.Black}
              text="Пополнить"
              onClick={() =>
                navigate("/profile/payment/")}
            />
          </div>
        </>
      )}
      <div className="header__user" onClick={clickOnMobile}>
        {isUserLoggedIn && getMyProfileData ? (
          <>
            <div
              ref={submenu}
              className={`header__user-desctop ${isUserLoggedIn ? `header__user--shadow ${isOpen ? "active" : ""}` : ""
              }`}
            >
              <UserInfo
                avatar={(
                  <UserAvatar
                    mobileImage={getMyProfileData.getMyProfile.user.mobileImage}
                    desktopImage={getMyProfileData.getMyProfile.user.desktopImage}
                    name={getMyProfileData.getMyProfile.user.name}
                    userId={getMyProfileData.getMyProfile.user.id}
                    type={UserAvatarTypeEnum.HeaderSmall}
                  />
                )}
              />
              <div className="user-menu__container">
                <UserMenu profileData={getMyProfileData.getMyProfile} />
              </div>
            </div>
            {/* <Link to="/profile/account"> */}
            <Button
              text="Аккаунт"
              className="header__user-mobile link"
              size={ButtonSizeEnum.Small}
              color={ButtonColorEnum.White}
              icon={IconTypeEnum.User}
              iconPosition={ButtonIconPositionEnum.Left}
              disabled={getMyProfileLoading}
              onClick={() =>
                setRedirectMobileProfile("profile")}
            />
            {/* </Link> */}
          </>
        ) : (
          <>
            <Button
              text="Войти"
              className="header__login-btn-desctop"
              size={ButtonSizeEnum.Small}
              onClick={onAuthorizationLinkClick}
              disabled={getMyProfileLoading}
            />
            <Button
              text="Войти"
              className="header__login-btn-mobile link"
              size={ButtonSizeEnum.Small}
              color={ButtonColorEnum.White}
              icon={IconTypeEnum.User}
              iconPosition={ButtonIconPositionEnum.Left}
              onClick={onAuthorizationLinkClick}
              disabled={getMyProfileLoading}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default User;
